export class GrecaptchaV2 {

    constructor() {
        this.errorElement = undefined;
        this.elementId = undefined;
    }

    setErrorContainer(containerElement) {
        if (containerElement instanceof jQuery) {
            this.errorElement = containerElement;
        }
    }

    setElementId(elementId) {
        this.elementId = elementId;
    }

    getElementId() {
        return this.elementId;
    }

    resetErrorContainer() {
        if (typeof this.errorElement !== 'undefined'
            && this.errorElement instanceof jQuery
        ) {
            this.errorElement.empty();
        }
    }

    /**
     * Store Google reCaptcha V2 widgets in elementId-widgetId pair
     * @param number widgetId
     */
    storeWidget(widgetId) {
        if (typeof window.grecaptchaWidgets === 'undefined') {
            window.grecaptchaWidgets = {};
        }

        if (widgetId === null) {
            console.warn('Widget ID must be number, null given');
        }

        window.grecaptchaWidgets[this.getElementId()] = widgetId;
    }

    /**
     * Get Google reCaptcha widget based on element ID selector
     * @param elementId
     * @return {number|null}
     */
    getWidget() {
        if (typeof window.grecaptchaWidgets !== 'undefined'
            && typeof this.getElementId() !== 'undefined'
            && typeof window.grecaptchaWidgets[this.getElementId()] !== 'undefined') {
            return window.grecaptchaWidgets[this.getElementId()];
        }
        /**
         * if it's not stored, we try to find in DOM, and store it
         * @type {NodeListOf<Element>}
         */
        const recaptchaBoxes = document.querySelectorAll('.g-recaptcha');
        const targetElement = document.querySelector(`#${this.getElementId()}`);
        if (targetElement === null || typeof targetElement === 'undefined' || targetElement.length === 0) {
            console.warn('targetElement not found');
            return null;
        }
        for (let i = 0; i < recaptchaBoxes.length; i++) {
            if (recaptchaBoxes[i].id === targetElement.id) {
                this.storeWidget(i);
                return i;
            }
        }

        console.warn('Google reCaptcha list or element not exists');
        return null;
    }

    /**
     * Visible "I'm not a robot" CAPTCHA
     * @param elementId - Target element ID
     */
    init(elementId) {
        this.setElementId(elementId);
        if (typeof window.grecaptcha !== 'undefined') {
            let widgetId = null;
            window.grecaptcha.ready(() => {
                // If element not already rendered, then we rendered it
                try {
                    widgetId = window.grecaptcha.render(document.querySelector(`#${elementId}`), {
                        sitekey: $(`#${elementId}`).data('sitekey'),
                    });
                }
                catch (error) {
                    /* If the element is already rendered, then we try to get the widgetId,
                        which is simply the position of the element
                        with elementId in .g-recaptcha collection
                    */
                    widgetId = this.getWidget(elementId);
                }

                /*
                  It's possible the widgetId is still null, so we don't store it
                 */
                if (widgetId !== null) {
                    this.storeWidget(widgetId);
                }
            });
        }
    }

    reset() {
        if (typeof window.grecaptcha !== 'undefined') {
            if (typeof this.errorElement !== 'undefined') {
                this.resetErrorContainer();
            }
            const widgetId = this.getWidget();
            if (widgetId !== null && typeof widgetId === 'number') {
                window.grecaptcha.reset(widgetId);
            }
            else {
                console.warn('widgetId is null');
            }
        }
    }

}
