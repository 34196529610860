// Desktop min width
const DESKTOP_WIDTH = 1200;

/**
 * Is on mobile device
 * @return {boolean}
 */
export function isMobile() {
    return window.innerWidth <= DESKTOP_WIDTH;
}
