import {ga4Push} from './ga4-utilities';

export class HeaderSearch {

    constructor() {
        this.input = undefined;
        this.form = undefined;
        this.topHeader = undefined;
        this.mobileMenu = undefined;
        this.headerTopSearch = undefined;

        this.init();
    }

    init() {
        this.initForm();
        this.initMobileSearchToggler();
    }

    /**
     * Init header search form behavior
     */
    initForm() {
        this.getForm().on('submit.headerSearch', event => {
            event.preventDefault();

            ga4Push({
                event: 'search',
                search_term: this.getSearchTerm(),
            });

            window.location.href = `${this.getSearchUrl()}/${this.getSearchTerm()}`;
        });
    }

    /**
     * Init header search toggle button
     */
    initMobileSearchToggler() {
        this.getTopHeader()
            .find('.mobile-search-toggle')
            .on('click.mobileSearchToggle', event => {
                event.preventDefault();
                this.getMobileMenu().removeClass('mobile-menu--open');
                this.getHeaderTopSearch().toggleClass('d-flex');
            });
    }

    /**
     * Return the redirect URL
     * @return {string}
     */
    getSearchUrl() {
        const searchUrl = this.getForm().data('searchUrl');
        return typeof searchUrl !== 'undefined' ? searchUrl : 'kereses';
    }

    /**
     * Return the header element
     * @return {*}
     */
    getTopHeader() {
        if (typeof this.topHeader === 'undefined') {
            this.topHeader = $('#topHeader');
        }

        if (this.topHeader.length === 0) {
            console.error('topHeader not found');
        }

        return this.topHeader;
    }

    /**
     * Returns the search input field
     * @return {*}
     */
    getInput() {
        if (typeof this.input === 'undefined') {
            this.input = $('#headerSearchInput');
        }

        if (this.input.length === 0) {
            console.error('headerSearchInput not found');
        }

        return this.input;
    }

    /**
     * Returns the header search form
     * @return {*}
     */
    getForm() {
        if (typeof this.form === 'undefined') {
            this.form = $('#headerSearchForm');
        }

        if (this.form.length === 0) {
            console.error('headerSearchForm not found');
        }

        return this.form;
    }

    /**
     * Returns the mobile menu container
     * @return {*}
     */
    getMobileMenu() {
        if (typeof this.mobileMenu === 'undefined') {
            this.mobileMenu = $('#mobileMenu');
        }

        if (this.mobileMenu.length === 0) {
            console.error('mobileMenu not found');
        }

        return this.mobileMenu;
    }

    /**
     * Returns the header search input value
     * @return {string}
     */
    getSearchTerm() {
        return this.getInput().val().trim();
    }

    /**
     * Returns the top search bar
     * @return {*}
     */
    getHeaderTopSearch() {
        if (typeof this.headerTopSearch === 'undefined') {
            this.headerTopSearch = $('#headerTopSearch');
        }

        if (this.headerTopSearch.length === 0) {
            console.error('headerTopSearch not found');
        }

        return this.headerTopSearch;
    }
}
