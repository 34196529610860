/**
 * Base component for homepage swipers
 */
export class BaseHomeSwiperComponent {

    /**
     * @param swiperClass {string}
     */
    constructor(swiperClass) {
        this.swiperClass = swiperClass;
        this.container = undefined;
        this._swiperConfig = {
            modules: [SwiperNavigation],
            slidesPerView: 'auto',
            spaceBetween: '24px',
            breakpoints: {
                '767': {
                    slidesPerView: 2,
                },
                '1200': {
                    slidesPerView: 3,
                },
                '1540': {
                    slidesPerView: 4,
                },
            },
        };
        this.init();
    }

    init() {
        this.initSwiper();
    }

    /**
     * Returns the class of the swiper
     * @return {*}
     */
    initSwiper() {
        const swiperInstance = new Swiper(this.swiperClass, {
            ...this._swiperConfig,
            navigation: {
                nextEl: `${this.swiperClass}-next`,
                prevEl: `${this.swiperClass}-prev`,
            },
            init: false
        });

        swiperInstance.on('init', () => {
            this.getContainer().find(`${this.swiperClass} img`).show();
        });

        swiperInstance.init();
    }

    /**
     * Returns the container
     * @return {*}
     */
    getContainer() {
        if (typeof this.container === 'undefined') {
            this.container = $('#content-wrapper');
        }

        if (this.container.length === 0) {
            throw {element: this.container, reason: 'container not found!'};
        }
        return this.container;
    }
}
