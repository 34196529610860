import { ToastMessage } from './toast-message';
import { formValidation } from './form-validation';
import { FetchHandler } from './fetchhandler';

/**
 * Validate a form and send it to backend
 * @param form {jQuery}
 * @return {boolean}
 */
export function ajaxForm(form) {
    if (form.length === 0) {
        throw {element: form, reason: 'not found'};
    }

    if (!form instanceof jQuery) {
        throw {element: form, reason: 'not a valid jQuery'};
    }

    if (!formValidation(form)) {
        return false;
    }

    const toastMessage = ToastMessage.getInstance();

    FetchHandler
    .create()
    .setUrl(form.attr('action'))
    .setBody(form.serialize())
    .send()
    .then((response) => {
        toastMessage.showSuccess(response.message);
        form[0].reset();
        form.trigger('success');
    })
    .catch((error) => {
        const errors = error.errors ?? null;

        form.find('.input-error').html('');

        if (errors !== null) {
            Object.entries(errors).forEach(([key, message]) => {
                const inputElement = form.find(`[name="${key}"]`);
                const errorElement = inputElement.closest('.form-input-wrapper').find('.input-error');

                inputElement.addClass('input--invalid');
                errorElement.html(message);
            });
        }
        else {
            toastMessage.showError(error.message);
        }
        form.trigger('error');
    });
}
