export class Countdown {

    /**
     * @param counterElement {jQuery}
     * @param end {null|string}
     */
    constructor(counterElement, end = null) {
        if (!counterElement instanceof jQuery) {
            console.error('counterElement is not a valid jQuery object');
        }

        if (end === null && typeof counterElement.data('endDate') === 'undefined') {
            console.error('data-end-date attribute is missing on counter!');
        }

        this.endDate = end ? new Date(end).getTime() : new Date(counterElement.data('endDate')).getTime();

        if (isNaN(this.endDate)) {
            throw new Error('the format of given date is incorrect! Please use ISO 8601 format (YYYY-MM-DDTHH:MM:SS)!');
        }

        this.daysElement = counterElement.find('.days');
        this.hoursElement = counterElement.find('.hours');
        this.minutesElement = counterElement.find('.minutes');
        this.secondsElement = counterElement.find('.seconds');
    }

    /**
     * Start the counter
     */
    start() {
       let days = 0;
       let hours = 0;
       let minutes = 0;
       let seconds = 0;

       let timer = setInterval(() => {
           const difference = this.endDate - new Date().getTime();
           const calcMinutes = 1000 * 60;
           const calcHour = calcMinutes * 60;
           const calcDay = calcHour * 24;

           if (isNaN(difference)) {
               clearInterval(timer);
               return;
           }

           days = Math.floor(difference / calcDay);
           hours = Math.floor((difference % calcDay) / calcHour);
           minutes = Math.floor((difference % calcHour) / calcMinutes);
           seconds = Math.floor((difference % calcMinutes) / 1000);

           this.daysElement.html(this.addLeadingZero(days));
           this.hoursElement.html(this.addLeadingZero(hours));
           this.minutesElement.html(this.addLeadingZero(minutes));
           this.secondsElement.html(this.addLeadingZero(seconds));

           if (difference < 0) {
               clearInterval(timer);
           }
       }, 1000);
    }

    /**
     * Add a leading zero to the given number
     * @param number {number}
     * @return {string}
     */
    addLeadingZero(number) {
        return String(number).padStart(2, '0');
    }
}
