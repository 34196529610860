import { ga4Push } from '../helper/ga4-utilities';

/**
 * Popular destinations swiper wrapper class
 */
export class PopularDestinationsComponent {

    constructor() {
        this.swiperClass = undefined;
        this.container = undefined;
        this.init();
    }

    init() {
        this.initSwiper();
    }

    initSwiper() {
        const swiperInstance = new Swiper(this.getSwiperClass(), {
            slidesPerView: 'auto',
            spaceBetween: 15,
            breakpoints: {
                '767': {
                    slidesPerView: 4,
                },
                '992': {
                    slidesPerView: 6,
                    spaceBetween: 0,
                    grid: {
                        rows: 2,
                    }
                }
            },
            init: false
        });

        swiperInstance.on('init', () => {
            this.getContainer().find(`${this.getSwiperClass()} img`).show();
            this.initMeasurement();
        });

        swiperInstance.init();
    }

    initMeasurement() {
        this.getContainer()
        .find(this.getSwiperClass())
        .find('.category-link')
        .on('click.destinatonClick', (event) => {
            const parentCard = $(event.currentTarget).closest('.js-card');
            if (parentCard.length === 0) {
                return null;
            }
            const nameOfCategory = parentCard.find('.category-card-title').html();
            const index = Number(parentCard.index('.js-card')) + 1;

            ga4Push({
                event: 'select_content',
                content_type: 'popular_destinations',
                content_id: `${nameOfCategory} - ${index}`,
            });
        });
    }

    /**
     * Returns the class of the swiper
     * @return {*}
     */
    getSwiperClass() {
        if (typeof this.swiperClass === 'undefined') {
            this.swiperClass = '.popular-destinations-swiper';
        }
        return this.swiperClass;
    }

    /**
     * Returns the container
     * @return {*}
     */
    getContainer() {
        if (typeof this.container === 'undefined') {
            this.container = $('#content-wrapper');
        }

        if (this.container.length === 0) {
            throw {element: this.container, reason: 'container not found!'};
        }
        return this.container;
    }
}
