/**
 * A wrapper class to use the built-in Bootstrap Toast message
 */
export class ToastMessage {

    /**
     * @private
     */
    constructor() {
        this.toastInstance = undefined;
        this.container = $();
        this.getContainer();
    }

    static getInstance() {
        if (typeof this.toastInstance === 'undefined') {
            this.toastInstance = new ToastMessage();
        }
        return this.toastInstance;
    }

    /**
     * Show a success toast message
     * @param message {string}
     */
    showSuccess(message) {
        this.show(message, 'success');
    }

    /**
     * Show an error toast message
     * @param message {string}
     */
    showError(message) {
       this.show(message, 'danger');
    }

    /**
     * Init a specific type toast message
     * @param message {string}
     * @param type {string}
     */
    show(message, type) {
        const toastInstance = this.createMessageBox(message, type);
        toastInstance.show();
    }

    /**
     * Returns the toast message container element
     * @return {jQuery|HTMLElement}
     */
    getContainer() {
        if (this.container.length === 0) {
            this.container = $(`<div class="toast-container position-fixed top-0 end-0 p-3 text-white" id="toastContainer"></div>`);
            $('body').append(this.container);
        }
        return this.container;
    }

    /**
     * Add a toast message box into the container
     * @param message
     * @param type
     * @return {*}
     */
    createMessageBox(message, type) {
        const bgClass = ['success', 'danger', 'primary'].includes(type) ? type : 'primary';
        const randomNumber = Math.floor(Math.random() * 10000);
        const toastId = `toastMessage_${randomNumber}`;

        this.getContainer().append(this.formatMessageTemplate({bgClass, toastId, message}));
        const toastBox = document.getElementById(`${toastId}`);
        const toastInstance = Toast.getOrCreateInstance(toastBox);
        toastBox.addEventListener('hidden.bs.toast', () =>{
            toastInstance.dispose();
            toastBox.remove();
        })
        return toastInstance;
    }

    /**
     * Returns the HTML template with already replaced values
     * @param bgClass {string}
     * @param toastId {string}
     * @param message {string}
     * @return {string}
     */
    formatMessageTemplate({ bgClass, toastId, message }) {
        return this.getMessageTemplate()
            .replace(':bgClass', bgClass)
            .replace(':toastId', toastId)
            .replace(':message', message);
    }

    /**
     * Return the toast message box template
     * @return {string}
     */
    getMessageTemplate() {
         return `<div class="toast align-items-center text-bg-:bgClass border-0" role="alert" aria-live="assertive" aria-atomic="true" id=":toastId">
            <div class="d-flex">
                <div class="toast-body">
                    :message
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>`;
    }
}
