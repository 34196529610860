import { countdownHelper } from '../helper/countdown.helper';
import { NewsletterComponent } from '../components/newsletter.component';
import { HeaderSearch}  from '../helper/header-search';
import {ga4Push} from '../helper/ga4-utilities';
import {isMobile} from "../helper/is-mobile";

export class Pages {
    /**
     * Init the page
     * @param container {jQuery}
     */
    constructor(container = $()) {
        this.container = container.length === 0 ? $('#content-wrapper') : container;
        if (this.container.length === 0) {
            console.error('Page container not found');
        }
        this.initCountdowns();
        this.newsletter = new NewsletterComponent();
        this.headerSearch = new HeaderSearch();
        this.googlePageInitContainer = undefined;
    }

    /**
     * Return the main container element
     * @return {jQuery}
     */
    getContainer() {
        return this.container;
    }

    /**
     * Init promotion countdowns
     */
    initCountdowns() {
        countdownHelper(this.container);
        const promotionBanner = $('#promotionBanner');
        if (promotionBanner.length > 0) {
            countdownHelper(promotionBanner);
            if (isMobile()) {
                promotionBanner.on('click.promotionBanner', event => {
                    const urlAttribute = $(event.currentTarget).find('.campaign-cta').attr('href');
                    if (urlAttribute.length > 0) {
                        window.location.href = urlAttribute;
                    }
                });
            }

            const windowObj = $(window);
            windowObj.on('scroll.window load.window', () => {
                /**
                 * The sticky bar had a special vibration effect
                 * on iPhone so we just add the class once, instead of toggleClass
                 */
                if (windowObj.scrollTop() > 200) {
                    if (!promotionBanner.hasClass('countdown--sticky')) {
                        promotionBanner.addClass('countdown--sticky');
                    }
                } else {
                    promotionBanner.removeClass('countdown--sticky');
                }
            });
        }
    }

    /**
     * Returns with the header search component
     * @return {HeaderSearch}
     */
    getHeaderSearch() {
        return this.headerSearch;
    }

    /**
     * Returns with the newsletter component
     * @return {NewsletterComponent}
     */
    getNewsletter() {
        return this.newsletter;
    }
}
