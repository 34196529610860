import {ajaxForm} from '../helper/ajax-form';
import {GrecaptchaV2} from './grecaptcha/grecaptcha-v2';
import {ga4Push} from "../helper/ga4-utilities";

export class NewsletterComponent {

    constructor() {
        this.form = undefined;
        this.grecaptcha = new GrecaptchaV2();
        this.init();
    }

    init() {
        this.initGrecaptcha();
        this.initSendEvent();
    }

    /**
     * Initialize Google reCAPTCHA
     */
    initGrecaptcha() {
        this.grecaptcha.init('newsletterSubscribeCaptcha');
    }

    /**
     * Initialize the submit event
     */
    initSendEvent() {
        this.getForm()
        .on('success.newsletterForm', () => {
            const subscribeType = this.getForm().find('[name="subscription-from"]');
            if (subscribeType.length > 0) {
                ga4Push({
                    event: 'newsletterSubscribe',
                    subscribe_type: subscribeType.val()
                });
            }
        })
        .on('submit.newsletterForm', (event) => {
            event.preventDefault();
            ajaxForm(this.getForm());
            this.grecaptcha.reset();
        });
    }

    /**
     * Returns with the form element
     * @return {*}
     */
    getForm() {
        if (typeof this.form === 'undefined') {
            this.form = $('#newsletter-form');
        }

        if (this.form.length === 0) {
            throw { form: this.form, reason: 'not found' };
        }

        return this.form;
    }
}
