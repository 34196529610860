import {Pages} from './pages';
import {PopularDestinationsComponent} from '../components/popular-destinations.component';
import {CategoriesComponent} from '../components/categories.component';
import {BaseHomeSwiperComponent} from '../components/base-home-swiper.component';
import {AccommodationsComponent} from '../components/accommodations.component';
import {HomeMeasurements} from './home-measurements';

class HomePage extends Pages {

    constructor() {
        super();
        this.init();
    }

    init() {
        this.initInlandDealListSwiper();
        this.initForeignDealListSwiper();
        this.initPpPackagesSwiper();
        this.initCampaignDealSwiper();
        this.initPopularDestinationSwiper();
        this.initCategorySwiper();
        this.initAccommodationSwiper();
        new HomeMeasurements(this.getContainer());
    }

    initInlandDealListSwiper() {
        new BaseHomeSwiperComponent('.deal-list-swiper');
    }

    initForeignDealListSwiper() {
        new BaseHomeSwiperComponent('.foreign-deal-list-swiper');
    }

    initCampaignDealSwiper() {
        if(this.getContainer().find('.campaign-deals').length > 0) {
            new BaseHomeSwiperComponent('.campaign-list-swiper');
        }
    }

    initPpPackagesSwiper() {
        new BaseHomeSwiperComponent('.pp-packages-swiper');
    }

    initPopularDestinationSwiper() {
        new PopularDestinationsComponent();
    }

    initCategorySwiper() {
        new CategoriesComponent();
    }

    initAccommodationSwiper() {
        new AccommodationsComponent();
    }
}

$(() => {
    new HomePage();
});
