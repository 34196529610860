/**
 * Do frontend basic validation
 * @param form {jQuery}
 * @return {*}
 */
export function formValidation(form) {
    if (form.length === 0) {
        throw {element: form, reason: 'not found'};
    }

    if (!form instanceof jQuery) {
        throw {element: form, reason: 'not a valid jQuery'};
    }

    form.find('input').each((index, element) => {
        const elem = $(element);
        const isValid = element.checkValidity();
        elem.toggleClass('input--invalid', !isValid);
        elem.next('.input-error').text(!isValid ? element.validationMessage : '');
        if (isValid) {
            form.trigger('validationError');
        }
    });
    return form[0].checkValidity();
}
